import { IComandaList, IComandaRequest } from "../interfaces/comanda/comanda";

export default function orderComandas(comandas: IComandaRequest): IComandaList[] {
	let comandasOrd: IComandaList[] = [];
	for (let i = 0; i < comandas.data.length; i++) {
		let handler = comandasOrd.find((comanda) => comanda.id === comandas.data[i].id);
		if (handler) {
			handler.servicios.push({
				servicio: comandas.data[i].servicio,
				servicioId: comandas.data[i].servicioId,
				productoId: comandas.data[i].productoId,
				cantidad: comandas.data[i].cantidad,
				precio: comandas.data[i].precio,
				fechaCreacion: comandas.data[i].fechaCreacion,
				observaciones: comandas.data[i].observaciones,
			});
			handler.empleadosId.push(comandas.data[i].empleadoId);
		} else {
			comandasOrd.push({
				id: comandas.data[i].id,
				empleadoId: comandas.data[i].empleadoId,
				empleadosId: [comandas.data[i].empleadoId],
				empleadoAtencion: comandas.data[i].empleadoAtencion,
				empleadoCreacion: comandas.data[i].empleadoCreacion,
				servicios: [
					{
						servicio: comandas.data[i].servicio,
						servicioId: comandas.data[i].servicioId,
						productoId: comandas.data[i].productoId,
						cantidad: comandas.data[i].cantidad,
						precio: comandas.data[i].precio,
						fechaCreacion: comandas.data[i].fechaCreacion,
						observaciones: comandas.data[i].observaciones,
					},
				],
				clienteNombre: comandas.data[i].clienteNombre,
				clienteApellido: comandas.data[i].clienteApellido,
				clienteId: comandas.data[i].clienteId,
				observacionesGenerales: comandas.data[i].observacionesGenerales,
				nroDocumento: comandas.data[i].nroDocumento,
			});
		}
	}
	return comandasOrd;
}
