/* eslint-disable array-callback-return */
import { useCallback, useEffect, useState, useContext } from "react";
// import { AiFillEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import { getComandaFinishedDetail } from "../../../request/comanda";
import {
	black_500,
	dark_purple,
	magenta_1,
	purple_1,
	soft_gray,
	violet_1,
} from "../../../components/styled/constant";
import { BodyContainer, BorderDiv, StyledLabel } from "../../../components/styled/styled";
import { IComandaDetail } from "../../../interfaces/comanda/comanda";
import { showLoader } from "../../../utils/loader";
import { userContext } from "../../../context/UserContext/userContext";
import useMixpanel from "hooks/useMixpanel/useMixpanel";
import { formatToSoles } from "utils/FormatsHandler";
import pencil from "../../../assets/visibility/pencil.svg";
import { capitalizeWords } from "utils/formatString";

interface IServiceState {
	id: number;
	state: string;
	price: number;
	attended: string;
	name: string;
	quantity: number;
}

export default function Detail() {
	const { track } = useMixpanel();
	const params = useParams();
	const navigate = useNavigate();
	const [services, setServices] = useState<{
		inCourse: IServiceState[];
		inComing: IServiceState[];
		finished: IServiceState[];
		products: IServiceState[];
	}>({ inCourse: [], inComing: [], finished: [], products: [] });

	const {
		userState: { user },
	} = useContext(userContext);

	const [isLoading, setIsLoading] = useState(true);
	const [clientData, setClientData] = useState<IComandaDetail["client"]>({
		id: 0,
		name: "",
		document: "",
		documentType: "",
		phone: "",
	});
	const [totalPrice, setTotoalPrice] = useState(0);
	const { id, editable } = params;

	const initialCharge = useCallback(async (id, editable) => {
		try {
			const response = await getComandaFinishedDetail(id);
			const data: IComandaDetail = response.data;
			// Transformación de datos del cliente
			setClientData({
				id: data.client.id,
				name: data.client.name,
				document: data.client.document,
				documentType: data.client.documentType,
				phone: data.client.phone,
			});
			setTotoalPrice(data.totalPrice);

			// Clasificación de servicios según su estado
			const serviceData = {
				inCourse: [] as IServiceState[],
				inComing: [] as IServiceState[],
				finished: [] as IServiceState[],
				products: [] as IServiceState[],
			};

			// Iteramos sobre los detalles
			data.servicesCompleted.forEach((detail: any) => {
				const serviceItem = {
					id: detail.detailId,
					state: detail.status || "",
					price: detail.price,
					attended: detail.employee.name,
					name: detail.description,
					quantity: detail.quantity,
				};
				serviceData.finished.push(serviceItem);
			});

			data.servicesPending.forEach((detail: any) => {
				const serviceItem = {
					id: detail.detailId,
					state: detail.status || "",
					price: detail.price,
					attended: detail.employee.name,
					name: detail.description,
					quantity: detail.quantity,
				};
				serviceData.inComing.push(serviceItem);
			});

			data.products.forEach((detail: any) => {
				const serviceItem = {
					id: detail.detailId,
					state: detail.status || "",
					price: detail.price,
					attended: detail.employee.name,
					name: detail.description,
					quantity: detail.quantity,
				};
				serviceData.products.push(serviceItem);
			});

			setServices(serviceData);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.error(error);
			// Manejo de errores...
		}
	}, []);

	useEffect(() => {
		if (id) {
			initialCharge(Number(id), editable);
		}
	}, [id, editable, initialCharge]);

	useEffect(() => {
		if (isLoading) {
			showLoader();
		} else {
			Swal.close();
		}
		return () => {
			Swal.close();
		};
	}, [isLoading]);

	return (
		<>
			<BodyContainer>
				<Main>
					<MainContainer>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "1rem",
							}}
						>
							<StyledEditLabel
								onClick={() => {
									navigate("/comandas");
								}}
							>
								{/* <IoArrowBack size={20} /> */}
								Volver
							</StyledEditLabel>
							{editable === "0" ? (
								""
							) : (
								<span
									style={{
										display: "flex",
										justifyContent: "flex-end",
									}}
								>
									<StyledEditLabel
										onClick={() => {
											track("Editar Comanda");
											navigate("editar");
										}}
										style={{
											display: "flex",
											alignItems: "center",
											gap: "0.5rem",
										}}
									>
										<img src={pencil} alt="pencil" />
										<span>Editar</span>
										{/* <StyledEditIcon size={22} /> */}
									</StyledEditLabel>
								</span>
							)}
						</div>

						<StyledClientTitle>Datos del cliente</StyledClientTitle>
						<BorderDiv>
							<StyledClientDataMain>
								{user?.tipoPrivacidadDatosCliente !== 3 ? (
									<>
										<ClientDataTitle>Tipo de documento</ClientDataTitle>
										<ClientDataContent>{clientData.documentType}</ClientDataContent>
										<ClientDataTitle>N° de documento</ClientDataTitle>
										<ClientDataContent>{clientData.document}</ClientDataContent>
									</>
								) : null}

								<ClientDataTitle>
									{clientData.documentType === "RUC"
										? "Razón social"
										: "Nombres y apellidos"}
								</ClientDataTitle>
								<ClientDataContent>{clientData.name}</ClientDataContent>

								<ClientDataTitle>N° de teléfono</ClientDataTitle>
								<ClientDataContent>
									{clientData.phone || "No registrado"}
								</ClientDataContent>
							</StyledClientDataMain>
						</BorderDiv>
						{services.inCourse.length > 0 && (
							<>
								<StateTitle>Servicios en curso</StateTitle>
								{services.inCourse.map((service: IServiceState) => {
									return (
										<InCourseDiv>
											<TitleServiceCard>
												<label>{service.name}</label>
												<label>{formatToSoles(service.price * service.quantity)}</label>
											</TitleServiceCard>
											<AtempededServiceCard color="white">
												Atendido por <i>{capitalizeWords(service.attended || "")}</i>
											</AtempededServiceCard>
										</InCourseDiv>
									);
								})}
							</>
						)}
						{services.inComing.length > 0 && (
							<>
								<StateTitle>Por atender</StateTitle>
								{services.inComing.map((service: IServiceState) => {
									return (
										<InComingDiv key={service.id}>
											<TitleServiceCard>
												<label>{service.name}</label>
												<label>{formatToSoles(service.price * service.quantity)}</label>
											</TitleServiceCard>
											<AtempededServiceCard>
												Será atendido por <i>{capitalizeWords(service.attended || "")}</i>
											</AtempededServiceCard>
										</InComingDiv>
									);
								})}
							</>
						)}
						{services.products.length > 0 && (
							<>
								<StateTitle>Productos añadidos</StateTitle>
								{services.products.map((service: IServiceState) => {
									return (
										<StyledProductsDiv key={service.id}>
											<TitleServiceCard>
												<ServiceName>{service.name}</ServiceName>
												<TotalPrice>
													{formatToSoles(service.price * service.quantity)}
												</TotalPrice>
											</TitleServiceCard>
											<DetailsContainer>
												<AtempededServiceCard color={"rgba(66, 66, 66, 0.6)"}>
													Vendido por <i>{capitalizeWords(service.attended || "")}</i>
												</AtempededServiceCard>
												<PriceDetails>
													{formatToSoles(service.price)} x {service.quantity}
												</PriceDetails>
											</DetailsContainer>
										</StyledProductsDiv>
									);
								})}
							</>
						)}
						{services.finished.length > 0 && (
							<>
								<StateTitle>Servicios terminados</StateTitle>
								{services.finished.map((service: IServiceState) => {
									return (
										<FinishedDiv key={service.id}>
											<TitleServiceCard>
												<label>{service.name}</label>
												<label>{formatToSoles(service.price * service.quantity)}</label>
											</TitleServiceCard>
											<AtempededServiceCard>
												Atendido por <i>{capitalizeWords(service.attended || "")}</i>
											</AtempededServiceCard>
										</FinishedDiv>
									);
								})}
							</>
						)}
						{services.inCourse.length === 0 &&
							services.inComing.length === 0 &&
							services.finished.length === 0 &&
							services.products.length === 0 && <StateTitle>No hay servicios</StateTitle>}
					</MainContainer>
				</Main>
			</BodyContainer>
			<FooterContainer>
				<StyledFooter>
					<label>Total a cobrar</label> <label>{formatToSoles(totalPrice)}</label>
				</StyledFooter>
			</FooterContainer>
		</>
	);
}

const Main = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 80%;
	color: ${magenta_1};
`;

const MainContainer = styled(Main)`
	justify-content: center;
	width: 100%;
	min-height: fit-content;
`;

// const StyledEditIcon = styled(AiFillEdit)`
// `;

const StyledEditLabel = styled(StyledLabel)`
	align-items: center;
	text-align: end;
	color: ${dark_purple};
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	text-decoration: underline;
	font-weight: 500;
	cursor: pointer;
	width: auto;
`;

const StyledClientTitle = styled.h2`
	color: rgb(69, 52, 142);
	font-weight: 800;
	margin: 1rem 0px 0.5rem 0px;
	display: flex;
	text-align: start;
	align-items: start;
	justify-content: start;
	width: 100%;
`;

const StyledClientDataMain = styled.div`
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	color: ${violet_1};
	flex-wrap: wrap;
`;

const ClientDataTitle = styled.label`
	color: #000;
	font-weight: 600;
	margin: 5px 0;
	width: 50%;
`;

const ClientDataContent = styled.label`
	color: #000;
	font-weight: 400;
	margin: 5px 0;
	width: 50%;
	word-break: break-all;
	hyphens: auto;
`;

const StateTitle = styled.label`
	color: ${purple_1};
	font-weight: 800;
	margin: 5px 0;
`;

const InCourseDiv = styled(BorderDiv)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #937cf4;
	border: none;
	color: white;
	width: -webkit-fill-available;
	min-height: 110px;
	justify-content: space-around;
`;
const InComingDiv = styled(BorderDiv)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${purple_1};
	width: -webkit-fill-available;
	justify-content: space-around;
	min-height: 110px;
	label {
		color: ${purple_1};
	}
`;
const FinishedDiv = styled(BorderDiv)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${violet_1}99;
	border-color: ${violet_1}99;
	width: -webkit-fill-available;
	justify-content: space-around;
	min-height: 110px;
	label {
		color: ${violet_1}99;
	}
`;

const TitleServiceCard = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	width: -webkit-fill-available;
	label {
		font-weight: 700;
		width: auto;
		word-break: break-all;
	}
	p {
		font-weight: 700;
		margin: 0;
		margin-bottom: auto;
	}
`;

const AtempededServiceCard = styled.label.attrs((props: { color?: string }) => ({
	color: props.color || "initial",
}))`
	font-weight: 500;
	margin: 5px 0;
	color: ${(props) => props.color};
`;

const StyledFooter = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	width: -webkit-fill-available;
	height: auto;
	min-height: 64px;
	background-color: ${purple_1};
	color: white;
	bottom: 0;
	left: 0;
	label {
		text-align: center;
		font-weight: 700;
	}
`;

const FooterContainer = styled.div`
	position: relative;
	bottom: 0;
`;

const StyledProductsDiv = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: -webkit-fill-available;
	background-color: ${soft_gray};
	padding: 1rem;
	border-radius: 0.5rem;
	margin: 0.5rem 0;
	min-height: 110px;
	justify-content: space-around;
`;

const ServiceName = styled.span`
	color: ${black_500};
	font-weight: 700;
`;

const TotalPrice = styled.span`
	color: ${black_500};
	font-weight: 700;
`;

const DetailsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const PriceDetails = styled.span`
	color: ${black_500}99;
	white-space: nowrap;
`;
