import axios from "axios";
import { IServiceHistory, IServiceHistoryRequest } from "../interfaces/service-history";
import fetchTimeout from "../utils/fetchTimeout";
import { decodeTokenLS } from "../utils/token";
import { setHeaders, URL_REQUEST } from "./constants";

export async function getServiceHistory(): Promise<IServiceHistory> {
	let response: IServiceHistoryRequest;
	let employeeId;
	let localId;
	const decoded = decodeTokenLS();
	if (decoded) {
		employeeId = decoded.user.empleadoId;
		localId = decoded.user.localId;

		const raw = JSON.stringify({
			localId: localId,
			empleadoId: employeeId,
		});

		let res: any = await fetchTimeout(
			URL_REQUEST + "/api/v1/admin/comanda/mvp/listarComandasFinalizadas",
			{
				headers: setHeaders(),
				body: raw,
				method: "post",
			}
		);

		response = await res.json();
		if (response.status === "200") {
			return response.data;
		}
		throw new Error(response.message);
	}
	throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}

export async function getServiceHistoryByDates(selectedDate: any): Promise<any> {
	let response: any;
	const decoded = decodeTokenLS();
	if (decoded) {
		const { token } = decoded;

		response = await axios
			.get(URL_REQUEST + "/comanda/getComandaHistory?date=" + selectedDate, {
				headers: {
					Authorization: token,
				},
			})
			.then((res) => res.data);

		// response = await res.json();
		if (response.status === "200") {
			return response.data;
		}
		throw new Error(response.message);
	}
	throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}

export async function getServiceHistoryByDates2(
	startDate: any,
	endDate: any
): Promise<any> {
	let response: any;
	const decoded = decodeTokenLS();
	if (decoded) {
		const { token } = decoded;

		response = await axios
			.get(
				`${URL_REQUEST}/comanda/getPeriodSaleHistory?startDate=${startDate}&endDate=${endDate}`,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((res) => res.data);
		response.data = response.data[0];

		// response = await res.json();
		if (response.status === "200") {
			return response.data;
		}
		throw new Error(response.message);
	}
	throw new Error("Token Invalido, por favor vuelva a iniciar sesion");
}
