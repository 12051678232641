import { IComandaDetail, IComandaRequest } from "../interfaces/comanda/comanda";
import fetchTimeout from "../utils/fetchTimeout";
import { decodeTokenLS } from "../utils/token";
import { setHeaders, URL_REQUEST } from "./constants";
import { IRequest } from "../interfaces/request";

export async function listarComandas(clienteNombre: string): Promise<IComandaRequest> {
	let decode = decodeTokenLS();
	if (!decode) {
		throw new Error("Token invalido");
	}

	const { localId, empleadoId } = decode.user;

	// Convertimos los parámetros a query string en lugar de usar body
	const queryParams = new URLSearchParams({
		localId: localId.toString(),
		empleadoId: empleadoId.toString(),
		clienteNombre: clienteNombre,
	}).toString();

	let res: any = await fetchTimeout(
		`${URL_REQUEST}/comanda/getComandasForEmployee?${queryParams}`,
		{
			headers: {
				...setHeaders(),
				Authorization: `${decode.token}`,
			},
			method: "GET",
		}
	);

	let responseData = await res.json();

	if (res.status === 200) {
		// Transformar la respuesta al nuevo formato
		const transformedData = {
			data: responseData.data.flatMap((comanda: any) => {
				// Función auxiliar para transformar servicios y productos
				const transformItem = (item: any, isService: boolean) => ({
					id: comanda.ComandaId,
					codigoComanda: comanda.ComandaLPAD,
					detalleId: item.ComandaDetailId,
					empleadoId: item.EmployeeId,
					empleadoAtencion: item.EmployeeName,
					empleadoCreacion: item.EmployeeName,
					servicio: isService ? item.PriceServiceDescription : item.ProductDesciption,
					cantidad: item.Quantity,
					cliente: comanda.ClientName,
					clienteNombre: comanda.ClientName.split(" ")[0] || "",
					clienteApellido: comanda.ClientName.split(" ").slice(1).join(" ") || "",
					clienteId: comanda.ClientId,
					fechaCreacion: item.startDate || new Date().toLocaleString(),
					precio: parseFloat(item.Price),
					observaciones: "",
					observacionesGenerales: "",
					servicioId: isService ? item.ServiceId : null,
					productoId: isService ? null : item.ProductId,
					paqueteId: null,
				});

				const items = [
					...comanda.Products.map((product: any) => transformItem(product, false)),
					...comanda.PendingService.map((service: any) => transformItem(service, true)),
					...comanda.ServiceCompleted.map((service: any) => transformItem(service, true)),
				];

				return items;
			}),
			status: "200",
			message: "",
		};

		return transformedData;
	}

	throw new Error(responseData.message);
}
type TClient = {
	tipoDocumentoId: number;
	nroDocumento: string;
	nombre: string;
	apellido: string;
	telefono: string;
	correo: string;
	sexo: number | string | null;
	fechaNacimiento: string | null;
};
type TPCreateComanda = {
	cliente: TClient;
	clienteId: number | null;
	observaciones: string;
	detalle: {
		servicioId: number | null;
		productoId: number | null;
		cantidad: number;
	}[];
};

export async function createComanda(params: TPCreateComanda): Promise<IRequest> {
	let decode = decodeTokenLS();
	if (!decode) {
		return { status: "500", message: "Token inválido" };
	}
	try {
		const requestData = {
			client: {
				clientId: params.clienteId,
				newClient: params.clienteId === null,
				clientData: {
					name: params.cliente.nombre,
					lastname: params.cliente.apellido,
					typeDocument: params.cliente.tipoDocumentoId,
					documentNumber: params.cliente.nroDocumento,
					email: params.cliente.correo,
					phone: params.cliente.telefono,
					gender: params.cliente.sexo,
					birthdate: params.cliente.fechaNacimiento,
				},
			},
			items: params.detalle.map((item) => ({
				...(item.servicioId
					? { priceServiceId: item.servicioId }
					: { localProductId: item.productoId }),
				quantity: item.cantidad,
			})),
			reserveId: null,
		};

		const raw = JSON.stringify(requestData);

		// Aseguramos que la URL y los headers sean correctos
		const response = await fetchTimeout(`${URL_REQUEST}/comanda/createComanda`, {
			method: "POST",
			headers: {
				...setHeaders(),
				Authorization: `${decode.token}`,
				"Content-Type": "application/json",
			},
			body: raw,
		});

		const data = await response.json();
		return data;
	} catch (error) {
		return {
			status: "500",
			message: "Ocurrió un error al procesar la solicitud",
		};
	}
}

export async function getComandaDetail(id: number): Promise<any> {
	let decode = decodeTokenLS();
	if (!decode) {
		throw new Error("Token inválido");
	}

	const res: any = await fetchTimeout(
		URL_REQUEST + "/comanda/getComandasForEmployeeDetails/" + id,
		{
			headers: {
				...setHeaders(),
				Authorization: `${decode.token}`,
			},
			method: "GET",
		}
	);

	let responseData = await res.json();

	if (responseData.status === "200") {
		// Función auxiliar para transformar items
		const transformItem = (item: any, isService: boolean = false) => ({
			localId: decode?.user?.localId,
			id: responseData.data.ComandaId,
			empresaId: decode?.user?.empresaId,
			detalleId: item.ComandaDetailId,
			empleadoId: item.EmployeeId,
			empleadoAtencion: item.EmployeeName,
			servicio: isService ? item.PriceServiceDescription : item.ProductDesciption,
			cantidad: item.Quantity,
			nombreCliente: responseData.data.ClientName,
			clienteTelefono: responseData.data.Phone || "",
			clienteNombre: responseData.data.ClientName.split(" ")[0],
			clienteApellido: responseData.data.ClientName.split(" ").slice(1).join(" "),
			clienteId: responseData.data.ClientId,
			distritoResidencia: null,
			tipoDocumento: responseData.data.DocumentDescription,
			nroDocumento: responseData.data.Document,
			fechaCreacion: item.startDate || new Date().toLocaleString(),
			precio: parseFloat(item.Price),
			observacionesGenerales: "",
			servicioId: isService ? item.PriceServiceId : 0,
			productoId: isService ? 0 : item.LocalProductId,
			nombreEstado: isService ? item.StatusDescription?.toUpperCase() : "EN ESPERA",
			estadoId: isService ? item.Status : 1,
			employeeAccess: item.EmployeeAccess,
			comandaDetailId: item.ComandaDetailId,
		});

		const transformedData = {
			status: "200",
			message: "",
			data: [
				// Transformar productos
				...responseData.data.Products.map((item: any) => transformItem(item, false)),
				// Transformar servicios completados
				...responseData.data.ServiceCompleted.map((item: any) =>
					transformItem(item, true)
				),
				// Transformar servicios en atención
				...(responseData.data.InAttention || []).map((item: any) =>
					transformItem(item, true)
				),
				// Transformar servicios pendientes
				...(responseData.data.PendingService || []).map((item: any) =>
					transformItem(item, true)
				),
			],
		};

		return transformedData;
	}

	throw new Error(responseData.message);
}
export async function getComandaFinishedDetail(comandaId: number): Promise<any> {
	let decode = decodeTokenLS();
	if (decode) {
		try {
			let res: any = await fetchTimeout(
				URL_REQUEST + `/comanda/getComandaHistoryDetails?comandaId=${comandaId}`,
				{
					headers: {
						Authorization: `${decode.token}`,
					},
					method: "GET",
				}
			);
			let response = await res.json();
			const data = response.data;
			if (response.status === "200") {
				const dataFormated: IComandaDetail = {
					client: {
						id: data.ClientId,
						name: data.ClientName,
						document: data.Document,
						documentType: data.DocumentDescription,
						phone: data.clientPhone,
					},
					servicesCompleted: response.data.ServiceCompleted.map((detail: any) => ({
						detailId: detail.ComandaDetailId,
						employee: {
							id: detail.EmployeeId,
							name: detail.EmployeeName,
							hasAccess: Boolean(detail.EmployeeAccess),
						},
						price: detail.Price,
						quantity: detail.Quantity,
						description: detail.PriceServiceDescription,
						status: {
							id: detail.Status,
							name: detail.StatusDescription,
						},
					})),
					servicesPending: response.data.PendingService.map((detail: any) => ({
						detailId: detail.ComandaDetailId,
						employee: {
							id: detail.EmployeeId,
							name: detail.EmployeeName,
							hasAccess: Boolean(detail.EmployeeAccess),
						},
						price: detail.Price,
						quantity: detail.Quantity,
						description: detail.PriceServiceDescription,
						status: {
							id: detail.Status,
							name: detail.StatusDescription,
						},
					})),
					products: response.data.Products.map((detail: any) => ({
						detailId: detail.ComandaDetailId,
						employee: {
							id: detail.EmployeeId,
							name: detail.EmployeeName,
							hasAccess: Boolean(detail.EmployeeAccess),
						},
						price: detail.Price,
						quantity: detail.Quantity,
						description: detail.ProductDesciption,
					})),
					totalPrice: response.data.TotalPrice,
				};

				response.data = dataFormated;

				return response;
			}
		} catch (error) {
			throw new Error("Ocurrio un error");
		}
	}
	throw new Error("Token invalido");
}
// src/request/comanda.ts

// Definir la interfaz para el nuevo body
interface IEditComandaRequest {
	comandaId: number;
	deleteItems: number[];
	comandaDetailEdit: {
		comandaDetailId: number | undefined;
		// LocalProductId?: number;
		quantity: number | undefined;
	}[];
	comandaDetailAdd: {
		LocalProductId?: number;
		PriceServiceId?: number;
		quantity: number;
	}[];
}

export async function editComanda(requestBody: IEditComandaRequest): Promise<IRequest> {
	let decode = decodeTokenLS();
	if (!decode) {
		return { status: "500", message: "Token inválido" };
	}

	try {
		const raw = JSON.stringify(requestBody);

		let res: any = await fetchTimeout(`${URL_REQUEST}/comanda/editComandaItem`, {
			headers: {
				...setHeaders(),
				Authorization: `${decode.token}`,
				"Content-Type": "application/json",
			},
			body: raw,
			method: "PUT",
		});

		return await res.json();
	} catch (error: any) {
		throw new Error(error?.response?.data?.message || "Error al editar la comanda");
	}
}
